.player{
    min-height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.time-control{
    width: 50%;
    display: flex;
    align-items: center;
    input{
        width: 100%;
        padding: 1rem 0rem;
        // -webkit-appearance: none; // It reset the slider style
        overflow: hidden;
        cursor: pointer;
    }
    p{
        padding: 1rem;
    }
}

.play-control{
    display:flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    width: 30%;
    svg{
        cursor: pointer;
    }
}
@media screen and (max-width:768px){
    .time-control{
        width: 90%;
    }
    .play-control{
        width: 60%;
    }
}


// input[type="range"]:focus{
//     outline: none;
// }
// input[type="range"]::-webkit-slider-thumb{
//     -webkit-appearance: none;
// }

// For Firefox
input[type="range"]::-moz-range-progress {
    background-color: black; 
  }
input[type="range"]::-moz-range-track {  
    background-color: #9a905d;
}