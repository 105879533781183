.lyrics{
    position: fixed;
    top: 0;
    left: 0;
    width: 20rem;
    height: 100%;
    background-color: white;
    overflow: scroll;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    opacity: 0;
    h2{
        padding: 2rem;
    }
    h3{
        padding: 0rem 2rem;
        line-height: 1.5;
    }
}   


*{
    scrollbar-width: thin;
    scrollbar-color: rgba(155,155,155,0.5);
}
*::-webkit-scrollbar{
    width: 5px;
}
*::-webkit-scrollbar-track{
    background: transparent;
}
*::-webkit-scrollbar{
    background-color: rgba(155,155,155,0.5);;
    border-radius: 20px;
    border: transparent;
}

.active-lyrics{
    transform: translateX(0%);
    opacity: 1;
}

@media screen and (max-width:768px){
    .lyrics{
        width: 100%;
    }
}