*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
h1,h2,h3{
    color: rgb(54, 54, 54)
}

body{
    font-family: 'Lato',sans-serif;
}

h2,h3{
    font-weight: 400;
}

.App{
    transition: all 0.5s ease;
}

.library-active{
    margin-left: 30%;
}

.lyrics-active{
    margin-left: 30%;
}

@import "./song";
@import "./player";
@import "./library";
@import "./nav";
@import "./lyrics"