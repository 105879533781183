.library{
    position: fixed;
    top: 0;
    left: 0;
    width: 20rem;
    height: 100%;
    background-color: white;
    overflow: scroll;
    transform: translateX(-100%);
    transition: all 0.5s ease;
    opacity: 0;
    h2{
        padding: 2rem;
    }
    .search-form{
        padding: 0rem 2rem 2rem 2rem;
    }
    .search-input{
        padding: 0.2rem 0.5rem;
        outline: none;
        border: none;
        border-bottom: 1px solid grey;
        border-radius: 5px;
        margin-right: 5px;
    }
    .search-button{
        padding: 0.2rem 0.5rem;
        border-radius: 5px;
        border: none;
        // color: dodgerblue;
        // background-color: teal;
        transition: all ease-in .3s;
        &:hover{
            cursor: pointer;
            background-color: grey;
            color: white;
        }
    }
}   

.library-song{
    display: flex;
    align-items: center;
    // flex-direction: column;
    cursor: pointer;
    padding: 1rem 2rem 1rem 2rem;
    transition: background-color 0.5s ease;
    img{
        width: 30%;
        border-radius: 5%;
    }
    &:hover{
        background-color: rgb(222, 222, 222);
    }
}

.song-description{
    margin-left: 1rem;
    h3{
        font-size: 1rem;
    }
    h4{
        font-size: 0.7rem;
    }
}


*{
    scrollbar-width: thin;
    scrollbar-color: rgba(155,155,155,0.5);
}
*::-webkit-scrollbar{
    width: 5px;
}
*::-webkit-scrollbar-track{
    background: transparent;
}
*::-webkit-scrollbar{
    background-color: rgba(155,155,155,0.5);;
    border-radius: 20px;
    border: transparent;
}

.active-library{
    transform: translateX(0%);
    opacity: 1;
}

@media screen and (max-width:768px){
    .library{
        width: 100%;
    }
}