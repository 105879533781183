.song-container{
    min-height: 60vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    img{
        width: 20%;
        border-radius: 5%;
    }
    h2{
        padding: 3rem 1rem 1rem 1rem;
    }
    h3{
        font-size: 1rem;
    }
}

@media screen and (max-width:768px){
    .song-container{
        img{
            width: 60%;
        }
    }
}