nav{
    min-height: 15vh;
    display: flex;
    justify-content: space-around;
    align-items: center;
    .logo-title{
        display: flex;
        gap: 10px;
        align-items: center;
    }
    button{
        background: transparent;
        border: none;
        border: 2px solid rgb(65, 65, 65);
        cursor: pointer;
        padding: 0.5rem;
        transition: all 0.3s ease;
        &:hover{
            background: rgb(65,65,65);
            color: white;
        }
    }
}

@media screen and (max-width:768px){
    nav{
        .buttonsDiv{
            z-index: 10;
        }
    }
}